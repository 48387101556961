<template>
  <div>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">Your size guides</h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="full-width kt-section mb-0">
            <loader v-if="isLoading"/>
            <table v-else-if="guides.length > 0" class="w-100 guides-table">
              <thead>
              <tr>
                <th>Name</th>
                <th>Attached to</th>
                <th>Status</th>
                <th width="150">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="guide in guides"
                  :key="guide.id">
                <td>
                  <router-link
                      :to="{ name: 'guide-edit', params: { id: guide.id }}"
                  >
                    <b>{{ guide.table_name }}</b>
                  </router-link>
                </td>
                <td>
                <span v-if=" guide.filters && guide.filters.products && guide.filters.products.length "
                      class="btn-sm btn btn-label btn-label-warning-o2">Some selected products</span>
                  <div v-else-if="guide.filters" class="d-flex">
                  <span v-if=" guide.filters.tag "
                        class="btn-sm btn btn-label btn-label-success mr-1">Tagged with {{ guide.filters.tag }}</span>
                    <span v-if=" guide.filters.vendor "
                          class="btn-sm btn btn-label btn-label-info mr-1">Vendor is {{ guide.filters.vendor }}</span>
                    <span v-if=" guide.filters.product_type "
                          class="btn-sm btn btn-label btn-label-brand">Product type is {{
                        guide.filters.product_type
                      }}</span>
                  </div>

                </td>
                <td>
                  <span v-if=" guide.published " class="btn btn-sm btn-label btn-label-success">Published</span>
                  <span v-else class="btn btn-sm btn-label">Draft</span>
                </td>
                <td class="d-flex">
                  <button @click="() => onEdit(guide.id, guide)" title="Hide from shop"
                          class="btn btn-icon btn-secondary btn-sm mr-4"><i class="fas fa-power-off"></i></button>

                  <router-link title="Edit guide" class="btn btn-icon btn-secondary btn-sm mr-4"
                               :to="{ name: 'guide-edit', params: { id: guide.id }}"
                  ><i class="fas fa-edit"></i></router-link>

                  <button
                      @click="() => { show_delete_confirm = true; deleteData = {id: guide.id, name: guide.table_name }}"
                      title="Remove guide"
                      class="btn btn-icon btn-secondary btn-sm"><i class="fas fa-times"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>

            <h3 v-else class="kt-section__title mb-0">No data</h3>

            <Pagination
                :change-page="onChangePage"
                :count="count"
                :default-page="1"
                :limit="limit"
            />
          </div>
        </div>
      </div>

      <confirm-modal
          :onConfirm="onEditConfirm"
          :onCancel="() => {show_edit_confirm = false; editData = {}; }"
          :text="`You want to ${editData.data && editData.data.published ? 'publish' : 'hide'} size guide '${editData.data ? editData.data.table_name : ''}'`"
          :show="show_edit_confirm"
          title=""
      />

      <confirm-modal
          :onConfirm="onDeleteConfirm"
          :onCancel="()=> { deleteData = {}; show_delete_confirm = false; }"
          :text="`Do you really want to delete '${deleteData.name}'?`"
          :show="show_delete_confirm"
          title=""
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {LOAD, EDIT, DELETE} from "../../store/modules/guides/types";
import {LIST_NAMES} from "../../store/modules/guides/interfaces";
import ConfirmModal from "../../components/ConfirmModal";
import {calculatePagesCount, debounce} from "../../services";
import {requestStatus} from "@/services/services";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";

export default {
  name: "GuidesList",
  components: {ConfirmModal, Loader, Pagination},
  data: () => ({
    offset: 0,
    limit: 25,
    search_value: "",
    show_edit_confirm: false,
    show_delete_confirm: false,
    editData: {},
    deleteData: {},
    isLoading: false
  }),
  computed: {
    ...mapState({
      guides: state => state.guides.list_tables,
      count: state => state.guides.tables_count,
      status: state => state.guides.status,
    }),
  },
  methods: {
    ...mapActions({
      load: `guides/${LOAD}`,
      edit: `guides/${EDIT}`,
      onDelete: `guides/${DELETE}`,
    }),

    onLoadList: function () {
      this.load({
        listName: LIST_NAMES.Guides,
        filter: `limit=${this.limit}&offset=${this.offset}&query=${this.search_value}`
      });
    },

    onEdit: function (id, data) {
      this.show_edit_confirm = true;
      this.editData = {id, name, data: {...data, published: !data.published}};
    },

    onEditConfirm: function () {
      this.edit({
        listName: LIST_NAMES.Guides,
        id: this.editData.id,
        data: {...this.editData.data}
      })
    },

    onDeleteConfirm: function () {
      this.onDelete({
        listName: LIST_NAMES.Guides, id: this.deleteData.id,
      })
    },

    onWaitChangeInput: function () {
      this.onLoadList();
    },


    onChangeInput: function (e) {
      e.preventDefault();
      this.onWaitChangeInput();
    },

    onChangePage: function (newValue) {
      if (newValue !== calculatePagesCount(this.offset, this.limit) + 1) {
        this.offset = (newValue - 1) * this.limit;
      }
    },
  },
  watch: {
    status(newValue) {
      if (
          newValue.change_field === "edit_tables" &&
          newValue.edit_tables === requestStatus.success
      ) {
        this.onLoadList();
        this.$toaster.success("Edit table success");
      }
      if (
          newValue.change_field === "delete_tables" &&
          newValue.delete_tables === requestStatus.success
      ) {
        this.onLoadList();
        this.$toaster.success("Delete table success");
      }

      this.isLoading = newValue.load_tables === requestStatus.loading;
    },
    offset() {
      this.onLoadList();
    }
  },

  created() {
    this.onLoadList();
    this.onWaitChangeInput = debounce(this.onWaitChangeInput, 500);
  }
}
</script>

<style scoped>

</style>